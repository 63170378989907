import '@popperjs/core';
import $ from "jquery/dist/jquery.slim";
import 'bootstrap';
import * as bootstrap from 'bootstrap';

import '@fortawesome/fontawesome-free/js/all'
import 'htmx.org';


window.bootstrap = bootstrap;
